import React, { useState } from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'

import Logo from '../images/living-church-header-logo.svg'
import MobileNav from '../images/icon-mobile-nav.svg'
import FacebookLogo from '../images/icon-facebook-black.svg'
import InstagramLogo from '../images/icon-instagram-black.svg'
import MobileLogo from '../images/living-church-header-logo-white.svg'
import MobileClose from '../images/icon-mobile-close.svg'
import MobileFacebookLogo from '../images/icon-facebook-white.svg'
import MobileInstagramLogo from '../images/icon-instagram-white.svg'

const HeaderBar = styled.header`
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;

  @media(min-width: 768px) {
    padding: 15px 40px 10px;
  }

  &.sticky-time {
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.50);
    background-color: var(--white);
    transition: 0.25s all;
    z-index: 1000;
  }
`

const Branding = styled.div`
  a {
    text-decoration: none;
    display: block;
    line-height: 1;
    height: 80px;
  }
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;

  nav {
    display: none;

    @media(min-width: 1150px) {
      display: flex;
    }
  }
`

const NavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`

const NavDropdown = styled.li`
  position: relative;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover .sub-nav {
    opacity: 1;
    transition: top 0s linear 0s, opacity .3s ease .01s;
    top: 40px;
  }

  &:last-child {
    background-color: var(--green);
    padding: 4px 10px 5px;
    border-radius: 3px;
    
    > a {
      color: var(--white);
      font-weight: var(--font-medium);

      &:hover {
        color: var(--black);
      }
    }
  }

  > a {
    color: var(--charcoal);
    font-size: 17px;
    font-weight: var(--font-medium);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--green);
      text-underline-offset: 3px;
    }
  }
`

const SubNav = styled.ul`
  opacity: 0;
  transition: opacity .3s ease 0s, transform 0s linear .9s;
  position: absolute;
  top: -1000px;
  background-color: var(--white);
  min-width: 240px;
  border: 1px solid var(--black);
  padding: 15px;
  z-index: 1;
  list-style: none;

  li {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--charcoal);
    font-size: 17px;
    font-weight: var(--font-medium);
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
      color: var(--green);
    }
  }
`

const NavSocialButtons = styled.div`
  display: none;

  @media(min-width: 1090px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  a {
    line-height: 1;
  }
`

const MobileNavigation = styled.div`
  display: block;

  @media(min-width: 1090px) {
    display: none;
  }
`

const MobileSlideIn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  min-height: 100vh;
  z-index: -999;
  background-color: var(--black);
  border-left: 2px solid var(--green);
  padding: 10px 25px 25px;
  overflow: auto;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    right: 0;
    opacity: 1;
    z-index: 999;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const MobileNavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 0;
  display: block;
`

const MobileNavDropdown = styled.li`
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;

  > a {
    color: var(--white);
    font-size: 22px;
    font-weight: var(--font-bold);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
      color: var(--green);
    }
  }
`

const MobileSubNav = styled.ul`
  opacity: 1;
  list-style: none;
  padding-left: 10px;
  padding-top: 20px;

  li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--white);
    font-size: 19px;
    font-weight: var(--font-regular);
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }
`

const MobileSocialButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  a {
    line-height: 1;
  }
`

export function Header() {

  const [isActive, setActive] = useState(false)

  const toggleClass = () => {
    setActive(!isActive)
  }

  // add sticky class on scroll
  if (typeof window !== 'undefined') {
    window.onscroll = function() {
      let body = document.body; //IE 'quirks'
      let doc = document.documentElement; //IE with doctype
      doc = (doc.clientHeight) ? doc : body;
      let distance = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      if (distance === 0) {
        document.querySelector('header').classList.remove('sticky-time');
        console.log("doc.scrollTop == 0");
      } else {
        document.querySelector('header').classList.add('sticky-time');
      }
    }
  }

  const data = useStaticQuery(graphql`
    {
      MainNav: allContentfulMenuLinksOrder(
        filter: {title: {eq: "Main Nav Links Order"}}
      ) {
        edges {
          node {
            contentful_id
            title
            navLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
              openLinkInNewTab
              navSubLinks {
                ... on ContentfulMenuLinks {
                  id
                  navLinkTitle
                  navLinkUrl
                  openLinkInNewTab
                }
              }
            }
          }
        }
      }
      HeaderSocial: allContentfulSocialMedia {
        edges {
          node {
            contentful_id
            facebookUrl
            instagramUrl
          }
        }
      }
    }
  `)

  return (
    <HeaderBar>
      <Branding>
        <a href='/'>
          <img src={Logo} width="158" height="80" alt="Living Church Logo" />
        </a>
      </Branding>
      <Navigation>
        <nav>
          {data.MainNav.edges.map(({ node }) => (
            <NavBar key={node.contentful_id}>
              {node.navLinksOrder && node.navLinksOrder.map((item) => (
                <NavDropdown key={`${item.contentful_id}-drop`}>
                  <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '"_blank" rel="noreferrer"' : '_self'}>{item.navLinkTitle}</a>
                  {item.navSubLinks &&
                    <SubNav className="sub-nav">
                    {item.navSubLinks && item.navSubLinks.map((subitem) => (
                      <li key={`${subitem.id}-sub`}>
                        <a href={subitem.navLinkUrl} target={subitem.openLinkInNewTab ? '"_blank" rel="noreferrer"' : '_self'}>{subitem.navLinkTitle}</a>
                      </li>
                    ))}
                    </SubNav>
                  }
                </NavDropdown>
              ))}
            </NavBar>
          ))}
        </nav>
        {data.HeaderSocial.edges.map(({ node }) => (
          <NavSocialButtons key={node.contentful_id}>
            <a href={node.facebookUrl} target="_blank" rel="noreferrer">
              <img src={FacebookLogo} width="30" height="30" alt="Facebook Logo" />
            </a>
            <a href={node.instagramUrl} target="_blank" rel="noreferrer">
              <img src={InstagramLogo} width="30" height="30" alt="Instagram Logo" />
            </a>
          </NavSocialButtons>
        ))}
        <MobileNavigation>
          <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
            <img src={MobileNav} width="30" height="12" alt="Mobile Menu Nav" />
          </div>
        </MobileNavigation>
      </Navigation>
      <MobileSlideIn className={isActive ? "active" : ""}>
        <div className="slide-in-content">
          <div className="mobile-header">
            <img src={MobileLogo} width="158" height="80" alt="Living Church Logo" />
            <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
              <img src={MobileClose} width="24" height="24" alt="Mobile Menu Nav" />
            </div>
          </div>
          <div className="mobile-nav">
            {data.MainNav.edges.map(({ node }) => (
              <MobileNavBar key={node.contentful_id}>
                {node.navLinksOrder && node.navLinksOrder.map((item) => (
                  <MobileNavDropdown key={`${item.contentful_id}-mobile-drop`}>
                    <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{item.navLinkTitle}</a>
                    {item.navSubLinks &&
                      <MobileSubNav className="sub-nav">
                      {item.navSubLinks && item.navSubLinks.map((subitem) => (
                        <li key={`${subitem.id}-mobile-sub`}>
                          <a href={subitem.navLinkUrl} target={subitem.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{subitem.navLinkTitle}</a>
                        </li>
                      ))}
                      </MobileSubNav>
                    }
                  </MobileNavDropdown>
                ))}
              </MobileNavBar>
            ))}
          </div>
          {data.HeaderSocial.edges.map(({ node }) => (
            <MobileSocialButtons key={node.contentful_id}>
              <a href={node.facebookUrl} target="_blank" rel="noreferrer">
                <img src={MobileFacebookLogo} width="30" height="30" alt="Facebook Logo" />
              </a>
              <a href={node.instagramUrl} target="_blank" rel="noreferrer">
                <img src={MobileInstagramLogo} width="30" height="30" alt="Instagram Logo" />
              </a>
            </MobileSocialButtons>
          ))}
        </div>
      </MobileSlideIn>
    </HeaderBar>
  );
}

export default Header
