import React from 'react'
import "@fontsource/yanone-kaffeesatz/700.css"
import "@fontsource/yanone-kaffeesatz/500.css"
import "@fontsource/figtree/400.css"
import "@fontsource/figtree/500.css"
import "@fontsource/figtree/700.css"

const Layout = require("./src/components/layout").default

export function wrapPageElement({ element, props }) {

  return (
    <>
      <Layout {...props}>{element}</Layout>
    </>
  )
}
