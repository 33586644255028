import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components"
import 'normalize.css'

import Header from './header'
import Footer from './footer'

const GlobalStyle = createGlobalStyle`
  :root {
    --body-font: 'Figtree', sans-serif;
    --heading-font: 'Yanone Kaffeesatz', sans-serif;

    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    --black: #1a1a1a;
    --charcoal: #333333;
    --white: #fff;
    --green: #5bba47;
    --light-green: #e8f7aa;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--body-font);
    font-weight: var(--font-regular);
    color: var(--charcoal);
    font-size: 19px;
    line-height: 1.2;
    box-sizing: border-box;

    @media(min-width: 768px) {
      font-size: 20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
      color: var(--charcoal);
      line-height: 1;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: var(--font-medium);
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }

    h1 {
      font-size: 47px;
      color: var(--black);
      font-weight: var(--font-bold);
  
      @media(min-width: 768px) {
        font-size: 50px;
      }
    }

    h2 {
      font-size: 39px;
  
      @media(min-width: 768px) {
        font-size: 42px;
      }
    }

    h3 {
      font-size: 34px;
  
      @media(min-width: 768px) {
        font-size: 36px;
      }
    }

    h4 {
      font-size: 29px;
  
      @media(min-width: 768px) {
        font-size: 31px;
      }
    }

    h5 {
      font-size: 25px;
  
      @media(min-width: 768px) {
        font-size: 28px;
      }
    }

    h6 {
      font-size: 22px;
  
      @media(min-width: 768px) {
        font-size: 25px;
      }
    }

    p {
      margin: 0 0 25px 0;
      line-height: 1.2;
      font-size: 18px;
      white-space: pre-wrap;
    }

    a {
      color: var(--green);
      text-decoration: underline;
    }

    .btn {
      padding: 12px 25px;
      font-weight: var(--font-bold);
      font-size: 19px;
      line-height: 1;
      text-align: center;
      color: var(--white);
      display: inline-block;
      text-decoration: none;
      background: var(--green);
      border: 1px solid var(--green);
      border-radius: 4px;
      text-transform: uppercase;

      &:hover {
        background: var(--charcoal);
        color: var(--white);
        border: 1px solid var(--charcoal);
        cursor: pointer;
      }
    }

    .btn-white {
      color: var(--charcoal);
      background: var(--white);
      border: 1px solid var(--charcoal);

      &:hover {
        color: var(--white);
        background: var(--charcoal);
        border: 1px solid var(--charcoal);
      }
    }

    .container {
      margin: 0 auto;
      max-width: 1200px;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;

      @media(min-width: 1240px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .container-sm {
      max-width: 850px;
    }

    .row-two-col {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 50px;
      row-gap: 30px;

      @media(min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`

const Layout = ({ children }) => {

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
