import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from 'gatsby'

import Logo from '../images/living-church-footer-logo.svg'
import FacebookLogo from '../images/icon-facebook-black.svg'
import InstagramLogo from '../images/icon-instagram-black.svg'

const FooterStyle = styled.footer`
  padding: 35px 20px 25px;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 40px;
  text-align: center;
  /* flex-wrap: wrap; */

  @media(min-width: 775px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`

const FooterColumnOne = styled.div`
`

const FooterColumnTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;

  @media(min-width: 775px) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
    gap: 40px;
  }

  @media(min-width: 1000px) {
    gap: 40px 120px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    @media(min-width: 775px) {
      padding-left: 40px;
      width: auto;
    }

    li {
      margin-bottom: 7px;

      a {
        color: var(--charcoal);
        font-size: 18px;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--green);
          text-underline-offset: 3px;
        }
      }
    }
  }
`

const FooterSocial = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  

  @media(min-width: 775px) {
    padding-left: 40px;
    width: auto;
    text-align: left;
  }
`

const Copyright = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 1160px;
  margin: 0 auto 20px auto;
  padding-left: 20px;
  padding-right: 20px;

  @media(min-width: 400px) {
    justify-content: space-between;
  }

  p,
  a {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 0;
  }
`

export function Footer() {
  const data = useStaticQuery(graphql`
    {
      FooterOneNav: allContentfulMenuLinksOrder(filter: {title: {eq: "Footer Column One"}}) {
        edges {
          node {
            contentful_id
            navLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
            }
          }
        }
      }
      FooterTwoNav: allContentfulMenuLinksOrder(filter: {title: {eq: "Footer Column Two"}}) {
        edges {
          node {
            contentful_id
            navLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
            }
          }
        }
      }
      FooterSocial: allContentfulSocialMedia {
        edges {
          node {
            contentful_id
            facebookUrl
            instagramUrl
          }
        }
      }
    }
  `)

  return (
    <>
      <FooterStyle>
        <FooterColumnOne>
          <a href='/'>
            <img src={Logo} width="220" height="89" alt="Living Church Logo" />
          </a>
        </FooterColumnOne>
        <FooterColumnTwo>
          {data.FooterOneNav.edges.map(({node}) => (
            <ul key={node.contentful_id}>
            {node.navLinksOrder.map((item) => (
              <li key={item.contentful_id}>
                <Link to={item.navLinkUrl} key={item.contentful_id}>{item.navLinkTitle}</Link>
              </li>
            ))}
            </ul>
          ))}
          {data.FooterTwoNav.edges.map(({node}) => (
            <ul key={node.contentful_id}>
            {node.navLinksOrder.map((item) => (
              <li key={item.contentful_id}>
                <Link to={item.navLinkUrl} key={item.contentful_id}>{item.navLinkTitle}</Link>
              </li>
            ))}
            </ul>
          ))}
          {data.FooterSocial.edges.map(({node}) => (
            <FooterSocial key={node.contentful_id}>
              <a href={node.facebookUrl} target="_blank" rel="noreferrer">
                <img src={FacebookLogo} width="30" height="30" alt="Facebook Logo" />
              </a>
              <a href={node.instagramUrl} target="_blank" rel="noreferrer">
                <img src={InstagramLogo} width="30" height="30" alt="Instagram Logo" />
              </a>
            </FooterSocial>
          ))}
          </FooterColumnTwo>
      </FooterStyle>
      <Copyright>
        <p>Copyright &copy; {new Date().getFullYear()} - Living Church, Inc.</p>
        <Link to='/privacy-policy/'>Privacy Policy</Link>
      </Copyright>
    </>
)}

export default Footer
